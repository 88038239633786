.progress-tracker {
    position: relative;
    height: auto;
    padding-left: 118px;
}

.progress-tracker::before {
    position: absolute;
    content: "";
    left: 0;
    width: 2px;
    height: 100%;
    background-color: var(--color-blue-grey-3);
    height: 100%;
    margin: 0 47px;
}

.progress-step {
    position: relative;
}

.progress-step:not(:last-child) {
    padding-bottom: 60px;
}

.progress-step::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    margin-left: -71px;
    background-color: transparent;
}

.progress-step::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 17px;
    height: 17px;
    left: -8.5px;
    margin-left: -71px;
    border: 2px solid var(--color-blue-grey-3);
    border-radius: 50%;
    z-index: 1;
    background-color: var(--color-grey);
}

.progress-step--progress::after {
    width: 33px;
    height: 33px;
    left: -16.5px;
    top: -5.5px;
    background-color: var(--color-primary);
    border: 7px solid var(--color-purple-2);
    transition: all 0.3s ease;
}

.progress-step--done::before {
    background-color: var(--color-purple-2);
}

.progress-step--done::after {
    background-color: var(--color-purple-2);
    border-color: var(--color-purple-2);
}

.progress-step:last-child::before {
    background-color: var(--color-blue-grey);
}
