.paginationContainer {
    display: flex;
    justify-content: center;
    margin-top: 20;
}

.progressContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
}

.progress {
    width: 15%;
}

.progressText {
    font-size: 12px;
    font-family: Rubik;
    color: #83889E;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10;
}

.pagination-item {
    padding-right: 10;
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-bottom: none;
    border-right: 0.5px solid #83889E;
    padding-left: 10;
}

.pagination-item-last-page {
    padding-right: 10;
    background-color: transparent;
    border: none;
    padding-left: 10;
}

.pagination-item-text {
    font-size: 14px;
    font-family: Rubik;
    color: #000;
    font-style: normal;
    font-weight: 400;
}

.pagination-item-text-disabled {
    font-size: 14px;
    font-family: Rubik;
    color: #BFBFBF;
    font-style: normal;
    font-weight: 400;
}

.pagination-item-text-active {
    font-size: 14px;
    font-family: Rubik;
    color: #6200EA !important;
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
}