.wysiwyg-editor-preview {
  padding: 48px 32px 62px;
}

.wysiwyg-editor-text-preview {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.wysiwyg-editor-text-preview div:nth-of-type(1) {
  padding-top: 10px;
}
.wysiwyg-editor-text-preview div:nth-of-type(2) {
  border: 1px solid gray;
  padding: 10px;
}
.wrapper-class {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 275px;
  /* Blue Gray / 200 */

  border: 1px solid #DFE0E6;
  border-radius: 3px;
}
.editor-class {
  height: auto;
  flex: 1;
  resize: vertical;
}
.toolbar-class {
  border: 0px #fff;
  display: flex;
  flex-direction: row;
}

.toolbar-class >*{
  display: flex;
  flex-direction: row;
}
