.store-detail-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fafafa;
  padding-bottom: 40px;
}
.store-detail-sub-container {
  overflow-y: auto;
}
.store-detail-main-container .header {
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #3C3E49;
  padding: 40px 24px;
  width: 100%;
}
.store-detail-main-container .store-detail-container {
  padding: 40px;
  width: 100%;
  max-width: 684px;
  background: #ffffff;
  border: 2px solid #ecedf0;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #3c3e49;
}
.label-store-details-configuration{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 50px;
}
.store-detail-main-container .label-store-details {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 30px;
}
.store-detail-main-container .input-label {
  margin-bottom: 8px;
  color: #000000;
}
.store-detail-main-container .store-input {
  width: 100%;
  height: 40px;
  border: 1px solid #c0c3ce;
  border-radius: 3px;
  padding-left: 10px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #83889e;
}
.store-detail-main-container .area-code-pre-icon,
.store-detail-main-container .currency-pre-icon {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-right: 8px;
}
.store-detail-main-container .area-code-content,
.store-detail-main-container .currency-content {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #3c3e49;
}
.store-detail-main-container .area-code-menu-item,
.store-detail-main-container .currency-menu-item {
  display: flex;
}

.store-detail-main-container .phone-number-container {
  width: calc(100% - 110px);
}

.country-code-img {
  display: inline-block;
  width: 20px;
  min-width: 20px;
  max-width: 20px !important;
  object-fit: contain;
  margin-right: 8px;
}
.store-detail-main-container .phone-number-info {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #83889e;
  padding-top: 8px;
}

.store-detail-main-container .validation-field{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: red;
  padding-top: 8px;
}

.store-detail-main-container .whatsapp-integration-label {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #3c3e49;
}

.store-detail-main-container .txt-variant {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #83889e;
}

.store-detail-main-container .area-code-text-field-container {
  display: flex;
  overflow: hidden;
  height: 53px;
  padding-top: 5px;
  position: relative;
}
.store-detail-main-container .message-label-container {
  display: flex;
  justify-content: space-between;
}
.store-detail-main-container .address-container {
  margin-top: 24px;
}

.store-detail-main-container .store-details-section-divider {
  border-bottom: 1px solid #ECEDF0;
  height: 1px;
  margin-bottom: 40px;
  padding-top: 16px;
}

.pb-24{
  padding-bottom: 24px !important;
}

.store-detail-main-container .MuiSelect-iconOutlined {
  display: none !important;
}