.customer-feedback-page {}

.customera.customer-feedback-page.emptyPageContainer-feedback-page.tableContainer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
}
.customer-feedback-page .delete-items {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0;
  text-decoration-line: underline;
  color: #ff1744;
  cursor: pointer;
  margin: 0 0 0 1em;
}
.customer-feedback-page.tableContainer {
  background-color: #f8f9fa;
  height: 100%;
  padding-left: 25;
  padding-right: 25;
}
.customer-feedback-page .validation-field{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: red;
  padding-top: 8px;
}

.customer-feedback-page.tableContainer .header {
  width: 100%;
  padding-top: 40;
  padding-bottom: 40;
}

.customer-feedback-page.tableContainer .header-title-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.customer-feedback-page.tableContainer .header-title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #3c3e49;
}

.customer-feedback-page.tableContainer .header-buttons {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.customer-feedback-page.tableContainer .header-settings-button {
  border: none;
  outline: none;
  background: transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  color: #3c3e49;
}

.customer-feedback-page.tableContainer .header-create-button-customer-feedback {
  border: none;
  outline: none;
  display: flex;
  text-align: center;
  flex-direction: row;
  align-items: center;
  padding: 10px 32px;
  background: #00d659;
  border-radius: 3px;
  width: 245px;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #3c3e49;
}

.customer-feedback-page.customer-feedback-create-container {
  background-color: #f8f9fa;
  height: 100%;
  padding-left: 25;
  padding-right: 25;
}

.customer-feedback-page .header-customer-feedback-create {
  width: 100%;
  padding-top: 40;
  padding-bottom: 40;
}

.customer-feedback-page .header-customer-feedback-create-title {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #3c3e49;
}

.customer-feedback-page .header-customer-feedback-create-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  margin-bottom: 8px;
}

.customer-feedback-page .customer-feedback-create-label-back {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #676b7e;
}

.customer-feedback-page .customer-feedback-create-label-back:hover {
  cursor: pointer;
}

.customer-feedback-page .customer-feedback-create-bottom-container {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.customer-feedback-page .customer-feedback-create-details-container {
  width: 70%;
  border: 2px solid #ecedf0;
  border-radius: 3px;
}

.customer-feedback-page .customer-feedback-details-title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #3c3e49;
  margin-bottom: 30px;
}

.customer-feedback-page .customer-feedback-details-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.customer-feedback-page .editor-description-limit-error {
  color: #d50000;
  font-size: 0.75rem;
  margin: 3px 0 0;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
}

.customer-feedback-page .customer-feedback-details-input-label {
  display: flex;
  justify-content: space-between;
}

.customer-feedback-page .customer-feedback-details-input-label span:nth-of-type(1) {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000;
  margin-bottom: 6px;
}

.customer-feedback-page .customer-feedback-details-input-label span:nth-of-type(2) {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #000000;
}

.customer-feedback-page .customer-feedback-create-right-side {
  width: 30%;
  gap: 25px;
  display: flex;
  flex-direction: column;
}

.customer-feedback-page .customer-feedback-create-activated-container {
  background-color: #fff;
  padding: 40px;
  border: 2px solid #ecedf0;
  border-radius: 3px;
}

.customer-feedback-page .customer-feedback-create-activated-cell-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.customer-feedback-page .customer-feedback-create-address-container {
  background-color: #fff;
  padding: 40px;
}

.customer-feedback-page .customer-feedback-create-switch-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.customer-feedback-page .customer-feedback-details-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}

.customer-feedback-page .customer-feedback-create-address-detail-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.customer-feedback-page .customer-feedback-create-edit-address-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid #00d659;
  width: fit-content;
  gap: 5px;
}

.customer-feedback-page .customer-feedback-create-edit-address-separator {
  height: 2px;
  width: 100%;
  background-color: #ecedf0;
  margin-top: 40;
  margin-bottom: 25;
}

.customer-feedback-page .customer-feedback-create-add-address-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid #00d659;
  width: fit-content;
  gap: 5px;
}

.customer-feedback-page .customer-feedback-create-modal-title {
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
}

.customer-feedback-page .customer-feedback-activated-hint {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #83889e;
  margin-top: 26px;
}
.customer-feedback-page .mb-40 {
  margin-bottom: 40px !important;
  background-color: #fff;
  height: 50px
}
