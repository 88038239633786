.main-container-create-static {
  background: #f8f9fa;
  padding: 25;
}
.main-container-create-static .container-right-side-panel {
  width: 30%;
}
.main-container-create-static .label-status {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  align-items: center;
  letter-spacing: -0.02em;
  color: #3c3e49;
}
.main-container-create-static .container-status {
  box-sizing: border-box;
  background: #ffffff;
  border: 2px solid #ecedf0;
  border-radius: 3px;
  padding: 40;
}
.main-container-create-static .switch-label {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}

.main-container-create-static .static_page_container {
  display: flex;
  gap: 25px;
}
.main-container-create-static .static_page_view_container {
  width: 70%;
}
.main-container-create-static .faq-bottom-sp {
  width: 100%;
  margin-top: 19px;
  margin-bottom: 40px;
}

.main-container-create-static .static_page_right_container {
  max-width: 30%;
  width: 30%;
  background-color: #ffffff;
  border: 1px solid #e8e8e8;
}
.main-container-create-static .create_static_page_title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  display: flex;
  letter-spacing: -0.02em;
  color: #3c3e49;
  padding: 4px 0px 24px 0px;
}
.main-container-create-static .message {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #676b7e;
  margin-top: 20;
  margin-bottom: 20;
}
.main-container-create-static .static_pate_title {
  position: absolute;
  width: 107px;
  height: 28px;
  left: 302px;
  top: 250px;

  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #3c3e49;
}
.main-container-create-static .static_pate_type {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 25px;
  color: #3c3e49;
}
.main-container-create-static .answer-label {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  flex: 1;
}
.main-container-create-static .switch_bottom_text {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #83889e;
}

.main-container-create-static .no_static_page_title {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #3c3e49;
  text-align: center;
}
.main-container-create-static .static-page-title-create-static {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  display: flex;
  margin-right: 10;
  letter-spacing: -0.02em;
  color: #3c3e49;
  text-align: center;
}
.main-container-create-static .backButton {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #676b7e;
  cursor: "pointer";
  display: "flex";
  align-items: "center";
}
.main-container-create-static .button-add-another {
  margin-top: 20;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.main-container-create-static .faq-header-section {
  display: flex;
  align-items: "center";
}
.main-container-create-static .faq-header-left {
  display: flex;
  flex: 1;
  align-items: center;
}
.main-container-create-static .delete-faq {
  height: 24px;
  width: 24px;
}
.main-container-create-static .quetion-create-static {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  align-items: center;
  letter-spacing: -0.02em;
  color: #3c3e49;
  margin-right: 24px;
}

.main-container-create-static .description-length {
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #000000;
}
.main-container-create-static .left-section-top {
  background: "#fff";
  border: "2px solid #ECEDF0";
  border-radius: "3px";
  padding: "40px";
}

.main-container-create-static .faq-container {
  background: "#fff";
  border: "2px solid #ECEDF0";
  border-radius: "3px";
}
.main-container-create-static .answer-container {
  display: "flex";
  align-items: "center";
  margin-bottom: 5;
  margin-top: 25;
  flex: 1;
}
.main-container-create-static .back-image {
  cursor: "pointer";
  margin-right: 4;
}
.main-container-create-static .helper-text {
  color: #d50000;
  font-family: "Rubik", sans-serif;
  font-style: normal;
}
.main-container-create-static .editor-border {
  border: 1px solid #d50000;
}
