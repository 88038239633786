.account-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #fafafa;
    overflow-y: auto;
}

.account-main-container .header {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 28px;
    color: #3C3E49;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    width: 100%;
    line-height: 32px;
    margin-top: 41px;
    margin-left: 26px;
    letter-spacing: -0.02em;
}

.account-detail-main-container .account-header {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #3C3E49;
}
.account-detail-main-container .account-tier-type {
    text-transform: capitalize;
}

.account-main-container .logout {
    cursor: pointer;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: #83889E;
    margin-bottom: 42px;
}

.account-main-container .account-card-container {
    width: 100%;
    padding: 0 10%;
    display: flex;
    flex-wrap: wrap;
}

.account-detail-main-container .account-label-back {
    cursor: pointer;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #676B7E;
}

.account-detail-main-container .account-back-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.account-detail-main-container .account-arrow-back-icon {
    color: #676b7e;
    height: 17px;
}

.account-detail-main-container .account-card {
    width: 212px;
    min-width: 212px;
    height: 250px;
    margin: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #FFFFFF;
    border: 2px solid #ECEDF0;
    border-radius: 3px;
    padding: 26px;
}

.account-detail-main-container .account-card-section {
    height: 72px;
}

.account-detail-main-container .account-card-title {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #3C3E49;
}

.account-detail-main-container .account-card-content {
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #83889E;
}

.account-detail-main-container .account-card-icon {
    font-size: 17px;
    cursor: pointer;
}

.account-detail-main-container .account-sub-container {
    overflow-y: auto;
}
