.account-detail-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  overflow-y: auto;
}
.account-detail-main-container .account-detail-sub-container {
  overflow-y: auto;
}
.account-detail-main-container .account-detail-phone-container {
  display: flex;
  justify-content: space-between;
}
.change-password-dialog {
  padding: 30px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.account-detail-main-container .header {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  width: 100%;
  line-height: 32px;
  margin-top: 41px;
  padding-left: 26px;
  letter-spacing: -0.02em;
}
.account-detail-main-container .password-change-button {
  border: 2px solid #364f6b;
  border-radius: 5px;
  color: #364f6b;
  padding: 12px 50px;
  height: fit-content;
  background: #ffffff;
  white-space: nowrap;
  text-transform: none;
  margin-top: 20px;
}
.account-detail-main-container .account-detail-container {
  margin: 41px auto;
  width: 100%;
  max-width: 684px;
  background: #ffffff;
  border: 2px solid #ecedf0;
  border-radius: 3px;
  padding: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #3c3e49;
}
.account-detail-main-container .label-account-details {
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #000000;
}
.account-detail-main-container .input-label {
  margin-bottom: 8px;
  color: #000000;
}
.account-detail-main-container .account-input {
  width: 100%;
  height: 40px;
  border: 1px solid #c0c3ce;
  border-radius: 3px;
  padding-left: 10px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #83889e;
}
.account-detail-main-container .div-back {
  color: #676b7e;
}
